import { theme } from 'ui-library'
import cpColors from './colors'
import fontSizes from './fontSizes'
import lineHeights from './lineHeights'
import shadows from './shadows'
import zIndices from './zIndices'
import radii from './radii'
import breakpoints from './breakpoints'

const { spacing } = theme

const colors = {
  ...theme.colors,
  ...cpColors,
}

export {
  colors,
  spacing,
  fontSizes,
  lineHeights,
  shadows,
  zIndices,
  radii,
  breakpoints,
}
