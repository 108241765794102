import { normalize } from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import {
  LOCALE_ZH_TW,
  LOCALE_ZH_HK,
  LOCALE_ZH_MY,
  LOCALE_KO_KR,
} from '@/constants/locales'

import { useCase } from '@/theme/constants'

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  ${normalize}

  html {
    height: 100vh;
    font-family: 'Noto Sans', sans-serif, arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &[lang|=${LOCALE_ZH_TW}] {
      font-family: 'Noto Sans', 'Noto Sans TC', sans-serif, '.PingFang TC', 'Microsoft JhengHei';
    }
    &[lang|=${LOCALE_KO_KR}] {
      font-family: 'Noto Sans', 'Noto Sans KR', sans-serif, 'Malgun Gothic', Dotum;
    }
    &[lang|=${LOCALE_ZH_MY}] {
      font-family: 'Noto Sans', 'Noto Sans SC', sans-serif, '.PingFang SC', 'Microsoft YaHei';
    }
    &[lang|=${LOCALE_ZH_HK}] {
      font-family: 'Noto Sans', 'Noto Sans HK', sans-serif, '.PingFang HK', 'Microsoft JhengHei';
    }
  }

  body {
    font-size: 16px;
    width: 100%;
    /* smooth scrolling in ios */
    -webkit-overflow-scrolling: touch;
  }

  iframe {
    border: none;
  }

  *:focus {
    outline: none;
  }

  ol, ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a, address {
    text-decoration: none;
    color: inherit;
    font-style: normal;
  }

  input, button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
  }

  #modal:not(:empty) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: -webkit-fill-available;
    height: 100%;
    z-index: ${(props) => props.theme.zIndices[useCase.MODAL]};
    background-color: transparent;
  }

  #notify:not(:empty) {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: ${(props) => props.theme.zIndices[useCase.FLASH_NOTICE]};
  }

  #app {
    &.app-payment {
      min-height: 100vh;
      background-color: #f5f5f5;
    }
  }

  .gm-style {
    font: inherit;
  }
`

export default GlobalStyle
