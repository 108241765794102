export const useCase = {
  BASE: 'base',
  FLAT: 'flat',
  RAISED: 'raised',
  OVERLAY: 'overlay',
  DROPDOWN: 'dropdown',
  NAVIGATION: 'navigation',
  HEADER: 'header',
  BULLETIN: 'bulletin',
  FLASH_NOTICE: 'flash_notice',
  MASK: 'mask',
  MODAL: 'modal',
  DIALOG: 'dialog',
}
