import { createIntl, createIntlCache } from 'react-intl'

import {
  LOCALE_EN_US,
  LOCALE_ZH_TW,
  LOCALE_ZH_HK,
  LOCALE_KO_KR,
  LOCALE_ZH_MY,
  LOCALE_JA_JP,
  LOCALE_ZH_CN,
  DEFAULT_LOCALE,
} from '@/constants/locales'

export const translationMessages = {
  [LOCALE_ZH_TW]: () => import(`./translations/${LOCALE_ZH_TW}.json`),
  [LOCALE_EN_US]: () => import(`./translations/${LOCALE_EN_US}.json`),
  [LOCALE_JA_JP]: () => import(`./translations/${LOCALE_JA_JP}.json`),
  [LOCALE_KO_KR]: () => import(`./translations/${LOCALE_KO_KR}.json`),
  [LOCALE_ZH_HK]: () => import(`./translations/${LOCALE_ZH_HK}.json`),
  [LOCALE_ZH_MY]: () => import(`./translations/${LOCALE_ZH_CN}.json`), // zh-my map to zh-cn translations
}

const intlMap = new Map()

export const getIntl = async (locale) => {
  if (!intlMap.has(locale)) {
    const messages = await translationMessages[locale]()
    intlMap.set(
      locale,
      createIntl(
        {
          locale,
          messages,
        },
        createIntlCache()
      )
    )
  }
  return intlMap.get(locale)
}

export const getFallbackLocale = (locale) => {
  switch (locale) {
    case LOCALE_JA_JP:
    case LOCALE_KO_KR:
      return LOCALE_EN_US
    default:
      return DEFAULT_LOCALE
  }
}
