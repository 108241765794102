import '@cp-web/common/client/polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import loadable from '@loadable/component'
import { browserClient } from '@cp-web/common/apolloClients/affiliateClient'
import StyledProvider from '@cp-web/common/containers/StyleProvider'
import I18nProvider from '@cp-web/common/containers/I18nProvider'
import { LOCALE_ZH_TW } from '@cp-web/common/constants/locales'
import injectGlobalVersion from '@cp-web/common/utils/injectGlobalVersion'

injectGlobalVersion()

const App = loadable(() => import('./App'))

ReactDOM.createRoot(document.getElementById('app')).render(
  <BrowserRouter>
    <ApolloProvider client={browserClient()}>
      <StyledProvider>
        <I18nProvider
          locale={LOCALE_ZH_TW}
          localeMessages={{
            [LOCALE_ZH_TW]: () => import('../translations/zh-tw.json'),
          }}
        >
          <App />
        </I18nProvider>
      </StyledProvider>
    </ApolloProvider>
  </BrowserRouter>
)
