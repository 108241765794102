import { useCase } from './constants'

export default {
  [useCase.RAISED]: '0 1px 2px rgba(0, 0, 0, 0.08)',
  [useCase.DROPDOWN]: '0 2px 4px rgba(0, 0, 0, 0.08)',
  [useCase.NAVIGATION]: '0 3px 6px rgba(0, 0, 0, 0.08)',
  [useCase.HEADER]: '0 4px 8px rgba(0, 0, 0, 0.08)',
  [useCase.BULLETIN]: '0 5px 10px rgba(0, 0, 0, 0.08)',
  [useCase.FLASH_NOTICE]: '0 6px 12px rgba(0, 0, 0, 0.08)',
  [useCase.MODAL]: '0 7px 14px rgba(0, 0, 0, 0.08)',
  [useCase.DIALOG]: '0 8px 16px rgba(0, 0, 0, 0.08)',
}
