import React, { useState, useMemo, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import momentI18n from '@/moment-i18n'
import {
  LOCALE_ZH_TW,
  LOCALE_ZH_HK,
  LOCALE_ZH_MY,
  LOCALE_KO_KR,
  LOCALE_EN_US,
  LOCALE_JA_JP,
} from '@/constants/locales'

import { getFallbackLocale } from '@/i18n'

const MemoIntlProvider = React.memo(IntlProvider)

const fontUrlDict = {
  [LOCALE_ZH_TW]:
    'https://fonts.googleapis.com/css?family=Noto+Sans+TC:400,500,700&display=swap',
  [LOCALE_ZH_HK]:
    'https://fonts.googleapis.com/css?family=Noto+Sans+HK:400,500,700&display=swap',
  [LOCALE_ZH_MY]:
    'https://fonts.googleapis.com/css?family=Noto+Sans+SC:400,500,700&display=swap',
  [LOCALE_KO_KR]:
    'https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,700&display=swap',
  [LOCALE_JA_JP]:
    'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap',
  [LOCALE_EN_US]:
    'https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap',
}

const I18nProvider = ({
  children,
  locale,
  localeMessages,
  defaultLocaleMessage = null,
}) => {
  const fontUrls = useMemo(() => {
    const fontUrlSet = new Set([fontUrlDict[LOCALE_EN_US], fontUrlDict[locale]])
    return [...fontUrlSet]
  }, [locale])

  const [messages, setMessages] = useState(() => {
    if (typeof localeMessages[locale] === 'object') {
      // static import i18n json file
      return localeMessages[locale]
    }

    return defaultLocaleMessage
  })

  useEffect(() => {
    momentI18n.locale(locale)
    dayjs.locale(locale)
    ;(async () => {
      if (typeof localeMessages[locale] === 'object') return
      const fallbackMessage = await localeMessages[getFallbackLocale(locale)]()
      const currentLocaleMessage = await localeMessages[locale]()
      setMessages({ ...fallbackMessage, ...currentLocaleMessage })
    })()
  }, [locale])

  const htmlAttributes = { lang: locale }

  if (!messages) return null

  return (
    <MemoIntlProvider locale={locale} messages={messages}>
      <Fragment>
        <Helmet htmlAttributes={htmlAttributes}>
          {fontUrls.map((fontUrl) => (
            <link key={fontUrl} rel="stylesheet" href={fontUrl} />
          ))}
        </Helmet>
        {children}
      </Fragment>
    </MemoIntlProvider>
  )
}

I18nProvider.defaultProps = {
  locale: LOCALE_ZH_TW,
}

I18nProvider.propTypes = {
  locale: PropTypes.string,
  localeMessages: PropTypes.shape({}).isRequired,
}

export default I18nProvider
