import moment from 'moment-timezone'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localeEn from 'dayjs/locale/en'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/zh-hk'
import localeKo from 'dayjs/locale/ko'
import localeMy from 'dayjs/locale/zh-cn'
import {
  LOCALE_ZH_TW,
  LOCALE_ZH_HK,
  LOCALE_KO_KR,
  LOCALE_ZH_MY,
  LOCALE_EN_US,
} from '@/constants/locales'

moment.locale([LOCALE_ZH_TW, LOCALE_ZH_HK, LOCALE_ZH_MY], {
  weekdaysMin: ['日', '一', '二', '三', '四', '五', '六'],
})

moment.locale(LOCALE_KO_KR, {
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
})

// dayjs
dayjs.extend(localeData)

localeKo.name = LOCALE_KO_KR
dayjs.locale(localeKo, null, true)

localeEn.name = LOCALE_EN_US
dayjs.locale(localeEn, null, true)

localeMy.name = LOCALE_ZH_MY
dayjs.locale(localeMy, null, true)

export default moment
