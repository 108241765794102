import { useCase } from './constants'

export default {
  [useCase.BASE]: 0,
  [useCase.FLAT]: 1,
  [useCase.RAISED]: 2,
  [useCase.OVERLAY]: 8,
  [useCase.DROPDOWN]: 1000,
  [useCase.NAVIGATION]: 1010,
  [useCase.HEADER]: 1020,
  [useCase.BULLETIN]: 1030,
  [useCase.FLASH_NOTICE]: 1040,
  [useCase.MASK]: 1050,
  [useCase.MODAL]: 1060,
  [useCase.DIALOG]: 1070,
}
