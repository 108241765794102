const blues = [
  '#f0fdff',
  '#c7f7ff',
  '#99e6f7',
  '#6ccfeb',
  '#43b7de',
  '#1e9fd2',
  '#0f7aab',
  '#055885',
  '#003a5e',
  '#002138',
]
const greens = [
  '#EFFFEF',
  '#9ce090',
  '#72d466',
  '#4bc740',
  '#26ba1e',
  '#04ad00',
  '#008702',
  '#006105',
  '#003b05',
  '#001402',
]
const yellows = [
  '#fffce6',
  '#fff0a3',
  '#ffe47a',
  '#ffd752',
  '#ffc629',
  '#ffb300',
  '#d99100',
  '#b37100',
  '#8c5400',
  '#663a00',
]
const oranges = [
  '#fff3eb',
  '#ffd8c2',
  '#ffbb99',
  '#ff9b70',
  '#ff7847',
  '#f4511e',
  '#cf350e',
  '#a81f03',
  '#821100',
  '#5c0900',
]
const grays = [
  '#ffffff',
  '#fafafa',
  '#f5f5f5',
  '#e8e8e8',
  '#d9d9d9',
  '#bfbfbf',
  '#8c8c8c',
  '#595959',
  '#262626',
  '#000000',
  '#002138',
]

const peaches = [
  '#FFF0F4',
  '#FFD4E1',
  '#FCA9C5',
  '#F07AA5',
  '#E34F8A',
  '#D62872',
  '#B0195F',
  '#8A0C4B',
  '#630437',
  '#3D0223',
]

const primary = blues

const main = {
  base: blues[5],
}

export default {
  primary,
  blues,
  greens,
  yellows,
  oranges,
  grays,
  peaches,
  main,
}
