import moment from 'moment-timezone'
import dayjs from 'dayjs'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import cookies from 'js-cookie'
import {
  appLocales,
  DEFAULT_LOCALE,
  LOCALE_ZH_TW,
  LOCALE_ZH_MY,
  LOCALE_ZH_HK,
  LOCALE_EN_US,
  LOCALE_KO_KR,
  LOCALE_JA_JP,
  supportedLocaleSet,
} from '@/constants/locales'
// eslint-disable-next-line import/no-extraneous-dependencies
import { origin } from '~/env.config'

export const getLocale = (location = window.location) => {
  const candidateLocales = [
    location.pathname.substr(1, 5),
    cookies.get('locale'),
    DEFAULT_LOCALE,
  ]
  return candidateLocales.find((locale) => supportedLocaleSet.has(locale))
}

export const isClient = () => typeof window !== 'undefined'

export const isMomo = () => isClient() && /momo/.test(window.location.host)

export const getDomain = () =>
  isClient() ? `${window.location.protocol}//${window.location.host}` : ''

export const getOrderDateWeek = (checkInDate, checkOutDate) => ({
  checkInDate: checkInDate || null,
  checkOutDate: checkOutDate || null,
  checkInWeekDay: checkInDate
    ? moment(checkInDate, 'YYYY-MM-DD').format('YYYY-w')
    : null,
  checkOutWeekDay: checkOutDate
    ? moment(checkOutDate, 'YYYY-MM-DD').format('YYYY-w')
    : null,
  bookBeforeCheckInDays: checkInDate
    ? moment(checkInDate).diff(moment().startOf('day'), 'days')
    : '',
})

export const getTrackingPaymentValue = (payment) => {
  const lowCasePayment = payment.toLowerCase()
  switch (lowCasePayment) {
    case 'credit':
      return 'credit_card'
    default:
      return payment
  }
}

export const getNearbyForTracking = (() => {
  const defaultVal = [1, 2, 3, 4].reduce(
    (acc, j) => ({
      ...acc,
      [`spot_id_${j}`]: null,
      [`spot_type_${j}`]: null,
      [`distance_${j}`]: null,
    }),
    {}
  )

  return (nearbySpots) =>
    nearbySpots.reduce(
      (acc, spot, i) =>
        !isEmpty(spot)
          ? {
              ...acc,
              [`spot_id_${i + 1}`]: spot.id,
              [`spot_type_${i + 1}`]: spot.type,
              [`distance_${i + 1}`]:
                Math.round(parseFloat(spot.distance) * 100) / 100,
            }
          : acc,
      defaultVal
    )
})()

export const toDigit = (num) => `00${Math.floor(num)}`.slice(-2)

export const scrollToTop = (top) =>
  window.scrollTo({
    top,
    behavior: 'smooth',
  })

const needFloatCurrencies = ['USD', 'SGD', 'MYR']

export const toCommaNumber = (currency, num) => {
  const isNeedFloat =
    currency && needFloatCurrencies.includes(currency.toUpperCase())

  const options = {
    minimumFractionDigits: isNeedFloat ? 2 : 0,
    maximumFractionDigits: isNeedFloat ? 2 : 0,
  }

  return Number(num).toLocaleString('en-US', options)
}

export const toPriceText = (currency = '', num) =>
  `${currency} ${toCommaNumber(currency, num)}`

export const getDynamicImageByWidth = (imageServiceUrl, width) =>
  imageServiceUrl.replace(/\/(auto|\d+)xauto\//, `/${width}xauto/`)

export const getSupportPath = (locale) => {
  switch (locale) {
    case LOCALE_ZH_MY:
      return 'https://support.asiayo.com/hc/zh-cn'
    case LOCALE_ZH_TW:
    case LOCALE_ZH_HK:
    case LOCALE_KO_KR:
    case LOCALE_JA_JP:
      return `https://support.asiayo.com/hc/${locale}`
    case LOCALE_EN_US:
    default:
      return 'https://support.asiayo.com/hc/en-001'
  }
}

export const getQHPath = (locale) =>
  locale === LOCALE_ZH_TW
    ? '/zh-tw/special/tw_feature_quarantine_hotel/'
    : 'https://asiayo.com/event/home-isolation.html'

export const getOrderPath = (isLogin, locale) =>
  isLogin ? `/${locale}/orders/success` : `/${locale}/login/`

export const checkIsNonQH = (propertyTags) =>
  !_.map(propertyTags, 'code').includes('quarantine-hotel')

export const getLocaleName = (locale) => {
  try {
    return appLocales.find((localConfig) => localConfig.region === locale).name
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error locale', locale)
    throw e
  }
}

export function getReferrerUrl() {
  const regWebDomain = new RegExp(origin)
  try {
    // eslint-disable-next-line compat/compat
    const { host, pathname } = new URL(document.referrer)
    if (!regWebDomain.test(host) || pathname === window.location.pathname) {
      return null
    }
    if (/\/[^\/]+\/success\/[^\/]+/.test(pathname)) {
      return pathname.replace('/success/', '/orders/')
    }
    return document.referrer
  } catch (e) {
    return null
  }
}

export const getChildAgesArray = (agesString) => {
  if (!agesString) {
    return []
  }
  const agesArray = agesString.split(',')
  const error = agesArray.some((age) => !/^\d+$/.test(age))

  if (error) {
    return []
  }

  return agesArray.map((age) => Number(age))
}

// https://github.com/iamkun/dayjs/issues/320#issuecomment-537885327
export const isDateValid = (date) =>
  dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date

export const isDateBefore = (date1, date2) =>
  dayjs(date1, 'YYYY-MM-DD').isBefore(date2, 'day')

export const isDateSame = (date1, date2) =>
  dayjs(date1, 'YYYY-MM-DD').isSame(dayjs(date2, 'YYYY-MM-DD'))

export const isDigit = (value) => /^\d+$/.test(value)

export const replaceToLokaliseKey = (
  text = '',
  translationFunction = () => {},
  replaceRegex = /\{\{lokalise_[a-zA-Z\_\.]*\}\}/g
) =>
  text.replaceAll(replaceRegex, (match) => {
    const target = match
      .replaceAll(/\{|\[|\(|\『/g, '')
      .replaceAll(/\}|\]|\)|\』/g, '')
      .replace('lokalise_', '')
    return translationFunction(target)
  })
