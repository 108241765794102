import React from 'react'
import { ThemeProvider } from 'styled-components'
import * as theme from '@/theme'
import GlobalStyle from './global-styles'

const StyleProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  </ThemeProvider>
)

export default StyleProvider
