import { ApolloClient, InMemoryCache, ApolloLink, gql } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RestLink } from 'apollo-link-rest'
import { affiliate } from '~/env.config'

const errLink = onError((requestInfo) => {
  const { graphQLErrors, networkError, operation } = requestInfo
  if (Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ message }) => {
      console.error(`[GraphQL error]: [Operation %O] - ${message}`, operation) // eslint-disable-line no-console
    })
  }

  if (networkError) {
    const message = networkError.message
    console.error(`[Network error]: [Operation %O] - ${message}`, operation) // eslint-disable-line no-console
  }
})

const restLink = new RestLink({
  headers: {
    'x-app-platform': 'web',
  },
  credentials: 'include',
  uri: `${affiliate.apiUri}/aff-api/v2/`,
  endpoints: {
    affiliate: {
      uri: `${affiliate.apiUri}/aff-api/v2/`,
    },
    auth: {
      uri: `${affiliate.apiUri}/auth-api/v1/`,
    },
  },
})

const links = ApolloLink.from([errLink, restLink])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const defaultCacheData = {
  query: gql`
    query GetMemberId {
      memberId
    }
  `,
  data: {
    memberId: null,
  },
}

export const browserClient = () => {
  const cache = new InMemoryCache()
  cache.writeQuery(defaultCacheData)
  return new ApolloClient({
    link: links,
    cache,
    defaultOptions,
    resolvers: {},
  })
}
