export const LOCALE_JA_JP = 'ja-jp'
export const LOCALE_KO_KR = 'ko-kr'
export const LOCALE_EN_US = 'en-us'
export const LOCALE_ZH_TW = 'zh-tw'
export const LOCALE_ZH_HK = 'zh-hk'
export const LOCALE_ZH_CN = 'zh-cn'
export const LOCALE_ZH_MY = 'zh-my'
export const LOCALE_TH_TH = 'th-th'
export const LOCALE_GLOBAL = 'zh-tw'
export const DEFAULT_LOCALE = LOCALE_GLOBAL

export const appLocales = [
  {
    region: LOCALE_ZH_TW,
    code: LOCALE_ZH_TW,
    name: '繁體中文(台灣)',
    isSupport: true,
  },
  {
    region: LOCALE_ZH_HK,
    code: LOCALE_ZH_HK,
    name: '繁體中文(香港)',
    isSupport: true,
  },
  {
    region: LOCALE_ZH_CN,
    code: LOCALE_ZH_CN,
    name: '简体中文',
    isSupport: false,
  },
  {
    region: LOCALE_ZH_MY,
    code: LOCALE_ZH_CN,
    name: '简体中文(马来西亚)',
    isSupport: true,
  },
  {
    region: LOCALE_EN_US,
    code: LOCALE_EN_US,
    name: 'English',
    isSupport: true,
  },
  {
    region: LOCALE_KO_KR,
    code: LOCALE_KO_KR,
    name: '한국어',
    isSupport: true,
  },
  {
    region: LOCALE_JA_JP,
    code: LOCALE_JA_JP,
    name: '日本語',
    isSupport: true,
  },
  {
    region: LOCALE_TH_TH,
    code: LOCALE_TH_TH,
    name: 'ภาษาไทย',
    isSupport: false,
  },
]

export const supportedLocaleSet = new Set(
  appLocales.filter((item) => item.isSupport).map((item) => item.region)
)


export const supportedLocales = [...supportedLocaleSet]

const supportedLocaleObjectSet = new Set(
  appLocales.filter((item) => item.isSupport)
)


export const supportedLocaleObject = [...supportedLocaleObjectSet]
