import { isClient } from '@/utils'

const injectGlobalVersion = () => {
  if (!isClient()) return

  window.version = process.env.VERSION
  if (process.env.DRONE_BUILD_NUMBER) {
    window.version += `+${process.env.DRONE_BUILD_NUMBER}`
  }
}

export default injectGlobalVersion
